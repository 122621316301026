// BS
@import "bootstrap/scss/functions";

// CUSTOm
$enable-dark-mode: false;
$spacer: 1rem;

$white: #fff;
$dark: #0b0f19;
$primary: #6366f1;
$secondary: #f3f6ff;
$body-color: #565973;

$navbar-dark-brand-color: $white;
$navbar-brand-font-size: 1.375rem;
$navbar-brand-font-weight: 800;
$navbar-brand-padding-y: $spacer * .5;

$navbar-nav-link-padding-x: .875rem;
$navbar-nav-link-padding-y: .875rem;

$nav-link-font-weight: 600;
$navbar-dark-color: $white;
$navbar-dark-active-color: $primary;
$navbar-dark-hover-color: $primary;

//$btn-font-weight: 600;
$btn-padding-x: 1.75rem;
$btn-padding-y: .625rem;

@import "bootstrap/scss/variables";
//@import "bootstrap/scss/variables-dark";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";

@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/card";

// Helpers
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

.fs-lg {
  font-size: 1.125rem;
}

.navbar-stuck {
  background-color: $dark;
}

.bg-gradient-primary {
  background: linear-gradient(90deg, $primary 0%, #8b5cf6 50%, #d946ef 100%);
}

.text-gradient-primary {
  background: linear-gradient(90deg, $primary 0%, #8b5cf6 50%, #d946ef 100%);
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
}

// Carousel
.carousel {
  .carousel-item {
    float: right;
    height: 100%;
    min-height: 600px;
    margin-right: 0;
    margin-left: -100%;
    background: linear-gradient(180deg, rgba(11, 15, 25, .58) 0%, rgba(11, 15, 25, .78) 100%), url("../../assets/img/hero-bg.webp") top / cover;
  }

  .carousel-caption {
    position: absolute;
    top: 39%;
    bottom: 1.25rem;
    width: 70%;
    height: 100%;
    color: $white;
    text-align: center;
  }

  .carousel-item h2 {
    font-weight: $font-weight-bold;
    color: $white;
  }
}

.icon-square {
  width: 4rem;
  height: 4rem;
  border-radius: .75rem;
}

.icon-square .bx {
  font-size: 2rem;
}

// RESET
img {
  max-width: 100%;
  height: auto;
}

ul {
  padding-inline-start: inherit;
}

// RESPONSIVE
@media (max-width: breakpoint-max("lg")) {
  .navbar .btn {
    width: 100%;
  }
  .navbar:not(.navbar-stuck) {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
  }
}
